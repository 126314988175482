<template>
    <div style="overflow:hidden">
        <TopNavBar2 class="tnb2"></TopNavBar2>
        <b-container fluid class="block8">
            <b-container>
                <b-row class="d-flex justify-content-center">
                    <p class="block3-p1">blog</p>
                </b-row>
                <b-row class="d-flex justify-content-center">
                    <h2 class="block2-h2">Related articles and news</h2>
                </b-row>
                <b-row class="text-left mt-5">
                    <b-col
                        md="4"
                        style="cursor:pointer"
                        v-for="(item, index) in Articles"
                        :key="index"
                        @click="loadArticle(item.slug)"
                    >
                        <img
                            :src="'https://pennlogisticsllc.com/' + item.cover"
                            style="width:100%"
                            alt
                        />
                        <h3 class="block4-h3 mt-3">{{ item.title }}</h3>
                        <p
                            class="block4-p"
                        >{{ item.content.replaceAll(/(\<(\/?[^>]+)>)/g, '').slice(0, 100) }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <form1></form1>
        <Footer1></Footer1>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import TopNavBar2 from '../components/TopNavBar2.vue'
import block8 from '../components/block8.vue'
import form1 from '../components/form.vue'
import Footer1 from '../components/footer.vue'
export default {
    metaInfo:{
        title: 'Pennsylvania Logistics Freight Shipping Blog | Tips & News', // set a title
        titleTemplate: '', // %s  required
        htmlAttrs: {
            lang: "en",
            amp: undefined // "amp" has no value
        },
        meta: [
            {
                'name': 'keywords',
                'content': 'freight transportation services, ftl shipping, ltl shipping, trucking, truckload, transportation company',
            },
            {
                'name': 'description',
                'content': 'Informative trucking articles & news concerning all aspects of the freight shipping industry. Get useful hints and tips from our freight shipping blog.',
            },
              {
                property: 'og:url',
                content: 'https:/pennlogisticsllc.com/blog',
                 vmid: 'og:url'
              },
              {
                property: 'og:title',
                content: 'Pennsylvania Logistics Freight Shipping Blog | Tips & News',
                vmid: 'og:title'
              },
              {
                property: 'og:description',
                content: 'Informative trucking articles & news concerning all aspects of the freight shipping industry. Get useful hints and tips from our freight shipping blog.',
                vmid: 'og:description'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },

        ],
        link: [
            { rel: 'canonical', href: 'https://pennlogisticsllc.com/blog' }
        ],
    },
    data() {
        return {
            metaData: {
                en: {
                    Homepage: {
                        
                    },
                },
            },
        }
    },
    name: 'Home',
    components: {
        TopNavBar2, form1, Footer1, block8
    },
    methods: {
        ...mapActions(['fetchArticles']),
        allArticles() {
            this.$router.push('/blog')
        },
        loadArticle(slug) {
            this.$router.push('/blog/' + slug)
        }
    },
    mounted() {
        this.fetchArticles()
    },
    computed: {
        MetaData() {
      return this.metaData['en']['Homepage']
    },
        ...mapGetters(['getArticles']),
        Articles() {
            return this.getArticles
        }
    }
}
</script>

